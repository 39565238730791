import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';
import { UserAuthFields, UserPostFields, UserPatchFields } from './../redux/types/userTypes';
import { UserState } from './../redux/types/userTypes';

interface User {
  name: string;
}

const createUser = async (postFields: UserPostFields, activation_code: string, term_id: number): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/users`,
    data: {
      user: { ...postFields },
      activation_code,
      term_id,
    },
  });
  return response?.data;
};

const loginUser = async (authFields: UserAuthFields): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/login`,
    data: {
      user: {
        email: authFields.email,
        password: authFields.password,
      },
    },
  });
  return response?.data;
};

const recoverPassword = async (email?: string): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/forgot_password`,
    data: {
      email,
    },
  });
  return response?.data;
};

const resetPassword = async (email?: string, token?: string, password?: string): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/reset_password`,
    data: {
      email,
      token,
      password,
    },
  });
  return response?.data;
};

const postNordic = async (
  anual_cuello: boolean,
  anual_hombros: boolean,
  anual_brazo_codo: boolean,
  anual_mano_muneca: boolean,
  anual_espalda_alta: boolean,
  anual_espalda_baja: boolean,
  anual_cadera_pierna: boolean,
  anual_rodilla: boolean,
  anual_tobillo_pie: boolean,
  sem_cuello: boolean,
  sem_hombros: boolean,
  sem_brazo_codo: boolean,
  sem_mano_muneca: boolean,
  sem_espalda_alta: boolean,
  sem_espalda_baja: boolean,
  sem_cadera_pierna: boolean,
  sem_rodilla: boolean,
  sem_tobillo_pie: boolean,
  p_espalda_baja: boolean,
  p_cuello: boolean,
  p_hombros: boolean,
  sem_nivel_cuello?: number,
  sem_nivel_hombros?: number,
  sem_nivel_brazo_codo?: number,
  sem_nivel_mano_muneca?: number,
  sem_nivel_espalda_alta?: number,
  sem_nivel_espalda_baja?: number,
  sem_nivel_cadera_pierna?: number,
  sem_nivel_rodilla?: number,
  sem_nivel_tobillo_pie?: number,
  espalda_baja_hospitalizado?: boolean | null,
  espalda_baja_cambio_trabajo?: boolean | null,
  espalda_baja_tiempo_anual?: string | null,
  espalda_baja_anual_laboral?: boolean | null,
  espalda_baja_anual_ocio?: boolean | null,
  espalda_baja_impedimentos_anual?: string | null,
  espalda_baja_atencion_medica_anual?: boolean | null,
  espalda_baja_problemas_sem?: boolean | null,
  espalda_baja_traumatismo?: boolean | null,
  espalda_baja_hinchazon?: boolean | null,
  cuello_hospitalizado?: boolean | null,
  cuello_cambio_trabajo?: boolean | null,
  cuello_tiempo_anual?: string | null,
  cuello_anual_laboral?: boolean | null,
  cuello_anual_ocio?: boolean | null,
  cuello_impedimentos_anual?: string | null,
  cuello_atencion_medica_anual?: boolean | null,
  cuello_problemas_sem?: boolean | null,
  cuello_traumatismo?: boolean | null,
  cuello_hinchazon?: boolean | null,
  hombros_hospitalizado?: boolean | null,
  hombros_cambio_trabajo?: boolean | null,
  hombros_tiempo_anual?: string | null,
  hombros_anual_laboral?: boolean | null,
  hombros_anual_ocio?: boolean | null,
  hombros_impedimentos_anual?: string | null,
  hombros_atencion_medica_anual?: boolean | null,
  hombros_problemas_sem?: boolean | null,
  hombros_traumatismo?: boolean | null,
  hombros_hinchazon?: boolean | null,
  brazo_codo_tiempo_anual?: string | null,
  brazo_codo_traumatismo?: boolean | null,
  brazo_codo_hinchazon?: boolean | null,
  mano_muneca_tiempo_anual?: string | null,
  mano_muneca_traumatismo?: boolean | null,
  mano_muneca_hinchazon?: boolean | null,
  espalda_alta_tiempo_anual?: string | null,
  espalda_alta_traumatismo?: boolean | null,
  espalda_alta_hinchazon?: boolean | null,
  cadera_pierna_tiempo_anual?: string | null,
  cadera_pierna_traumatismo?: boolean | null,
  cadera_pierna_hinchazon?: boolean | null,
  rodilla_tiempo_anual?: string | null,
  rodilla_traumatismo?: boolean | null,
  rodilla_hinchazon?: boolean | null,
  tobillo_pie_tiempo_anual?: string | null,
  tobillo_pie_traumatismo?: boolean | null,
  tobillo_pie_hinchazon?: boolean | null,
  user_id?: number,
): Promise<any> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/nordics`,
    data: {
      nordic: {
        anual_cuello,
        anual_hombros,
        anual_brazo_codo,
        anual_mano_muneca,
        anual_espalda_alta,
        anual_espalda_baja,
        anual_cadera_pierna,
        anual_rodilla,
        anual_tobillo_pie,
        sem_cuello,
        sem_hombros,
        sem_brazo_codo,
        sem_mano_muneca,
        sem_espalda_alta,
        sem_espalda_baja,
        sem_cadera_pierna,
        sem_rodilla,
        sem_tobillo_pie,
        sem_nivel_cuello,
        sem_nivel_hombros,
        sem_nivel_brazo_codo,
        sem_nivel_mano_muneca,
        sem_nivel_espalda_alta,
        sem_nivel_espalda_baja,
        sem_nivel_cadera_pierna,
        sem_nivel_rodilla,
        sem_nivel_tobillo_pie,
        p_espalda_baja,
        espalda_baja_hospitalizado,
        espalda_baja_cambio_trabajo,
        espalda_baja_tiempo_anual,
        espalda_baja_anual_laboral,
        espalda_baja_anual_ocio,
        espalda_baja_impedimentos_anual,
        espalda_baja_atencion_medica_anual,
        espalda_baja_problemas_sem,
        p_cuello,
        cuello_hospitalizado,
        cuello_cambio_trabajo,
        cuello_tiempo_anual,
        cuello_anual_laboral,
        cuello_anual_ocio,
        cuello_impedimentos_anual,
        cuello_atencion_medica_anual,
        cuello_problemas_sem,
        p_hombros,
        hombros_hospitalizado,
        hombros_cambio_trabajo,
        hombros_tiempo_anual,
        hombros_anual_laboral,
        hombros_anual_ocio,
        hombros_impedimentos_anual,
        hombros_atencion_medica_anual,
        hombros_problemas_sem,
        user_id,
        espalda_baja_traumatismo,
        espalda_baja_hinchazon,
        cuello_traumatismo,
        cuello_hinchazon,
        hombros_traumatismo,
        hombros_hinchazon,
        brazo_codo_tiempo_anual,
        brazo_codo_traumatismo,
        brazo_codo_hinchazon,
        mano_muneca_tiempo_anual,
        mano_muneca_traumatismo,
        mano_muneca_hinchazon,
        espalda_alta_tiempo_anual,
        espalda_alta_traumatismo,
        espalda_alta_hinchazon,
        cadera_pierna_tiempo_anual,
        cadera_pierna_traumatismo,
        cadera_pierna_hinchazon,
        rodilla_tiempo_anual,
        rodilla_traumatismo,
        rodilla_hinchazon,
        tobillo_pie_tiempo_anual,
        tobillo_pie_traumatismo,
        tobillo_pie_hinchazon,
      },
    },
  });
  return response?.data;
};

const getProfile = async (token?: string): Promise<any> => {
  const response: AxiosResponse<UserState> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json', Authorization: token ? `Bearer ${token}` : '' },
    method: 'get',
    url: `/profile`,
  });
  return response?.data;
};

const isRegistered = async (rut: string): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/is_registered`,
    data: {
      rut,
    },
  });
  return response?.data;
};

const phoneIsRegistered = async (phone: string): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/phone_is_registered`,
    data: {
      phone,
    },
  });
  return response?.data;
};

const completeRegistration = async (user: UserPatchFields): Promise<any> => {
  const response: AxiosResponse<User[]> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'patch',
    url: `/complete_registration`,
    data: {
      user: { ...user },
    },
  });
  return response?.data;
};

const userService = {
  createUser,
  loginUser,
  recoverPassword,
  resetPassword,
  postNordic,
  getProfile,
  isRegistered,
  phoneIsRegistered,
  completeRegistration,
};

export default userService;
